import React from 'react'

import DarkToggle from './DarkToggle';
import { Link } from 'react-router-dom';
import List from '@mui/material/List';
import IconButton from '@mui/material/IconButton';
import { styled, useTheme } from '@mui/material/styles';

//Icons
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import HomeIcon from '@mui/icons-material/Home';
import MapIcon from '@mui/icons-material/Map';
import AgricultureIcon from '@mui/icons-material/Agriculture';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import UserService from '../../services/user/userService';


export default function SideBarLinks(props) {
  const theme = useTheme();
  const {getUserInfo} = UserService();
  var user = getUserInfo();


  return (
    <List>
      <ListItem button component={Link} to="/">
        <ListItemIcon>
          <IconButton >
            <HomeIcon />
          </IconButton>
        </ListItemIcon>
        <ListItemText primary={"Home"} />
      </ListItem>

      <ListItem button component={Link} to="/map">
        <ListItemIcon>
          <IconButton >
            <MapIcon />
          </IconButton >
        </ListItemIcon>
        <ListItemText primary={"Mapa"} />
      </ListItem>

      <ListItem button component={Link} to="/tasks">
        <ListItemIcon>
          <IconButton >
            <AgricultureIcon />
          </IconButton >
        </ListItemIcon>
        <ListItemText primary={"Cuaderno de campo"} />
      </ListItem>

      {

        /* Admin routes */
        user.role === "ADMIN" &&
        <ListItem button component={Link} to="/users">
          <ListItemIcon>
            <IconButton >
              <AdminPanelSettingsIcon />
            </IconButton >
          </ListItemIcon>
          <ListItemText primary={"Gestión de personal"} />
        </ListItem>

      }


      <ListItem button onClick={props.toggleDark}>
        <ListItemIcon>
          <DarkToggle />
        </ListItemIcon>
        <ListItemText primary={"Modo " + (theme.palette.mode === "dark" ? "claro" : "oscuro")} />
      </ListItem>

    </List>
  )
}
