import * as React from 'react';
import Typography from '@mui/material/Typography';
import Title from '../Title';


export default function Deposits() {
  return (
    <React.Fragment>
      <Title>Temperatura y humedad Actual</Title>
      <Typography component="p" variant="h4">
        18.3 ºC
      </Typography>
      <Typography component="p" variant="h4">
        83 %
      </Typography>
      <Typography color="text.secondary" sx={{ flex: 1 }}>
        {/* Today date as text in spanish*/
        new Date().toLocaleDateString('es-ES', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}
      </Typography>
    </React.Fragment>
  );
}