import React from 'react'
import NotificationsIcon from '@mui/icons-material/Notifications';
import { Typography, Paper, Stack, IconButton } from '@mui/material'
import Delete from '@mui/icons-material/Delete';
import axios from 'axios';
import moment from 'moment';

export default function Notification(props) {

    console.log(props.notification._id);

    function deleteNotification() {
        const params = {
            id: props.notification._id
        }
        axios.delete(process.env.REACT_APP_API_BASE_URL + '/notifications/delete' , {params} )
            .then(res => {
                props.deleteNotification(props.notification.id);
            })
            .catch(err => {
                console.log(err);
            });
    }

    function markAsRead() {
       axios.put(process.env.REACT_APP_API_BASE_URL + '/notifications/markAsRead', { id: props.notification._id })
    }

    React.useEffect(() => {
        markAsRead();
    }, []);

    console.log(props.notification);

    return (
        <Paper elevation={2} sx={{ width: '100%' }}>
            <Stack
                spacing={2}
                direction={{ xs: 'column', md: 'row' }}
                justifyContent="space-between"
                alignItems="center"
                sx={{ m: 2 }} >

                <IconButton >
                    <Stack direction='column' alignItems='center'>
                        <Typography variant="body1" color='text.secondary' >
                            {props.notification.type}
                        </Typography>
                        <NotificationsIcon fontSize='large' />
                    </Stack>
                </IconButton>

                <Stack direction='column' alignItems='center'>
                    <Typography variant="body1" color='text.secondary' >
                        Título
                    </Typography>
                    <Typography variant="h6">
                        {props.notification.title}
                    </Typography>
                </Stack>

                <Stack direction='column' alignItems='center'>
                    <Typography variant="body1" color='text.secondary' >
                        Descripción
                    </Typography>
                    <Typography variant="h6">
                        {props.notification.message}
                    </Typography>
                </Stack>

                <Stack direction='column' alignItems='center'>

                    <Typography variant="body2"  >
                        {moment(props.notification.updatedAt).format('DD/MM/YYYY HH:mm A')}
                    </Typography>
                </Stack>
                <Stack direction='column' alignItems='center'>
                    <Typography variant="body1" color='text.secondary' >
                        Eliminar
                    </Typography>
                    <IconButton onClick={() => deleteNotification(props.notification.id)}>
                        <Delete fontSize='large' />
                    </IconButton>
                </Stack>

            </Stack>

        </Paper>
    )
}
