import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import background from "../../assets/images/background.jpg";
import backgroundClaro from "../../assets/images/backgroundClaro.jpg";
import { useTheme } from '@mui/material';


function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://agro-data.es/">
                AgroData
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}



export default function LoginBase(props) {
    
    const theme = useTheme();

    return (
        <Grid container component="main" sx={{ height: '100vh' }}>
            <CssBaseline />
            <Grid
                item
                xs={false}
                sm={4}
                md={7}
                sx={{
                    backgroundImage: theme.palette.mode === 'dark' ? `url(${background})` : `url(${backgroundClaro})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundColor: (t) =>
                        t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                }}
            />
            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                <Box
                    sx={{
                        my: 5,
                        mx: 4,
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >

                    {
                        
                        /* Child components */
                        props.children
                    }

                    <Copyright sx={{ mt: 5 }} />

                </Box>
            </Grid>
        </Grid>
    );
}