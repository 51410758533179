import * as React from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import AuthService from '../../services/auth/authService';
import { useSnackbar } from 'notistack';
import LoginBase from './LoginBase';
import LoadingIcon from './LoadingIcon';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';

//import setContext from '../services/auth/authService';


export default function Login(props) {

  const [loading, setLoading] = useState(false);
  const [showPass, setShowPass] = useState(false);

  const navigate = useNavigate();
  const { loginUser } = AuthService();
  const [error, setError] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleClickShowPassword = () => {
    setShowPass(!showPass);
  };

  const handleSubmit = (event) => {
    setLoading(true);
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    // eslint-disable-next-line no-console
    const credentials = {
      username: data.get('username'),
      password: data.get('password'),
      keepSession: data.get('keepSession')!=null
    }

    //If username or password are empty, show error
    if (credentials.username === '' || credentials.password === '') {

      setError(true);
      setLoading(false);
    } else {

      loginUser(credentials).then(
        () => {
          navigate('/');
        },
        (error) => {
          //if error is 401, show error message, else show generic error
          if (error.toString().includes(401)) {
            //Show error message
            enqueueSnackbar('Usuario y/o contraseña incorrectos', {
              variant: 'error',
              preventDuplicate: true,
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'center',
              },
            });

            setLoading(false);


          } else {
            //Show notistack error notification
            enqueueSnackbar("Parece que algo va mal, prueba de nuevo más tarde", {
              variant: 'error',
              preventDuplicate: true,
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'center',
              },
            });

            setLoading(false);

          }
        }
      );
    };
  };

  return (

    <>
      <LoginBase>

        <LoadingIcon loading={loading} />

        <Typography component="h1" variant="h5" align="center">
          Iniciar sesión
        </Typography>

        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            fullWidth
            id="username"
            label="Usuario"
            name="username"
            autoComplete="username"
            autoFocus
            error={error}
            helperText={error ? 'Rellene los campos Usuario y contraseña' : ''}
          />
          <TextField
            margin="normal"
            fullWidth
            name="password"
            label="Contraseña"
            type={showPass ? 'text' : 'password'}
            id="password"
            autoComplete="current-password"
            error={error}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    {showPass ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )
            }}

          />
          <FormControlLabel
            control={<Checkbox value="keepSession" name="keepSession" color="primary" />}
            label="Mantener sesión iniciada"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Iniciar Sesión
          </Button>

          <Link href="/forgotten-password"  >
            ¿Has olvidado tu contraseña?
          </Link>
        </Box>
      </LoginBase>
    </>
  );
}