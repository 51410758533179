import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { InputAdornment, IconButton, useTheme } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import axios from 'axios';
import { useEffect, useRef } from 'react';


export default function SearchLocation({ focusLocation }) {
  const [open, setOpen] = React.useState(false);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);
  const loading = open && options.length === 0;
  const isInitialMount = useRef(true);
  const theme = useTheme();

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      handleInputChange();
    }
  }, [inputValue]);

  function handleInputChange() {
    //axios request to get places from mapbox api
    axios.get('https://api.mapbox.com/geocoding/v5/mapbox.places/' + inputValue + '.json?access_token=' + process.env.REACT_APP_MAPBOX_API_KEY, {
      params: {
        proximity: 'ip',
        country: 'es',
        types: 'locality,place',
        limit: 10
      }
    })
      .then(res => {
        //delete duplicates by text field from the array of features
        const options = res.data.features.filter((item, index, self) =>
          index === self.findIndex((t) => (
            t.text === item.text
          ))
        );
        if (options.length === 0) {
          setOpen(false);
        }
        setOptions(options);
      })
      .catch(err => {
        console.log(err);
      })
  }


  return (
    <Autocomplete
      open={open}
      onInputChange={(event, newInputValue) => {
        if (newInputValue !== '') {
          setOpen(true);
          setInputValue(newInputValue);
        }
      }}
      onChange={(event, localidad) => {
        if (localidad) {
          focusLocation(localidad.center);
          console.log(localidad);
        }
      }}
      onClose={() => {
        setOpen(false);
      }}
      getOptionLabel={(feature) => feature.text}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      options={options}
      loading={loading}
      renderInput={(params) => (
        <TextField
          variant='filled'
          style={ theme.palette.mode === 'light' ? {backgroundColor: 'rgb(245, 245, 245, 0.8)'} : {backgroundColor:'rgb(0, 0, 0, 0.8)'} }
          label="Buscar ubicación"
          sx={{ width: "40%", position: 'absolute', left: "calc(50% - 20%)", top: "0.5em", zIndex: 1 }}

          {...params}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}