import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import AuthService from '../../services/auth/authService';
import LoginBase from './LoginBase';
import Box from '@mui/material/Box';
import LoadingIcon from './LoadingIcon';

export default function ForgottenPassword() {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const { requestNewPassword } = AuthService();

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const userInfo = {
            username: data.get('username'),
        }
        requestNewPassword(userInfo).then(
            () => {
                enqueueSnackbar('Si el usuario es correcto recibirá un correo electrónico para restablecer su contraseña', {
                    variant: 'success',
                    preventDuplicate: true,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'center',
                    }
                });
                navigate('/login');
            },
            (error) => {
                //Show notistack error notification
                enqueueSnackbar("Parece que algo va mal, prueba de nuevo más tarde", {
                    variant: 'error',
                    preventDuplicate: true,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'center',
                    },
                });
            }
        );
            
    }

    return (
        <LoginBase>
            
            <LoadingIcon loading={false} />
            <Typography component="h1" variant="h5" align="center" >
                ¿Has olvidado tu contraseña?
            </Typography>

            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                <TextField
                    margin="normal"
                    fullWidth
                    id="username"
                    label="Usuario"
                    name="username"
                    autoComplete="username"
                    autoFocus
                />

                <Typography color="primary" variant="body1" >
                    Introduce tu usuario y te enviaremos un enlace para que puedas restablecer tu contraseña
                </Typography>

                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className="submit"
                    sx={{ mt: 3, mb: 2 }}
                >
                    Restablecer contraseña
                </Button>
            </Box>
        </LoginBase>

    );
}

