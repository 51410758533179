import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Box } from '@mui/material'
import { Skeleton } from '@mui/material';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import moment from 'moment';

export default function AlertDialog({ parcela, open, onClose }) {

  const { enqueueSnackbar } = useSnackbar()
  const [loaded, setLoaded] = React.useState(false);
  const [parcelaInfo, setParcelaInfo] = React.useState();

  var image = new Image();

  console.log(loaded);

  const handleClose = () => {
    onClose();
  };

  //when parcela changes, update image
  React.useEffect(() => {
    setLoaded(false);
    if (parcela) {
      axios.get(process.env.REACT_APP_API_BASE_URL + '/parcelas/infoParcela/' +
        parcela.ref.prov + '/' + parcela.ref.mun + '/' + parcela.ref.agr + '/' + parcela.ref.zona + '/' +
        parcela.ref.pol + '/' + parcela.ref.par).then(res => {
          setParcelaInfo(res.data)
        });
    }
  }, [parcela]);


  const handleAddParcela = () => {
    //post para crear parcela
    axios.post(process.env.REACT_APP_API_BASE_URL + '/parcelas/add', parcela)
      .then(function (response) {
        //show notification
        enqueueSnackbar('Parcela creada correctamente', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        });
        console.log(response);
      })
      .catch(function (error) {
        //show error notification
        enqueueSnackbar('Error al crear parcela', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        });
        console.log(error);
      });
    onClose();
  };

  if (!parcela) {
    return null;
  }


  image.onload = () => { setLoaded(true) };
  image.src = ("https://geoserver.agro-data.es/parcelas/previsualizacionParcela/" +
    parcela.ref.prov + "/" +
    parcela.ref.mun + "/" +
    parcela.ref.agr + "/" +
    parcela.ref.zona + "/" +
    parcela.ref.pol + "/" +
    parcela.ref.par + "/" +
    parcela.ref.rec + "/");

  function getArea() {
    return parcelaInfo.parcelaInfo.dn_surface >= 10000 ? (parcelaInfo.parcelaInfo.dn_surface / 10000).toFixed(2) + " ha" : 
    parcelaInfo.parcelaInfo.dn_surface.toFixed(2) + " m2"
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Añadir parcela"}</DialogTitle>
        <DialogContent>
          {
            loaded ? (
              <CardMedia
                component="img"
                height="100%"
                image={image.src}
                alt={parcela.name}
              />

            ) : (
              //Centered skeleton
              <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                <Skeleton variant="rectangular" width={600} height={400} />
              </Box>
            )
          }
          <Typography paragraph variant="h6" color="text.secondary">
            <Box component="span" fontWeight='fontWeightMedium' color="text.primary">Referencia</Box>: {
              parcela.ref.prov + "" + parcela.ref.mun + "" + parcela.ref.agr + "" + parcela.ref.zona + ""
              + parcela.ref.pol + "" + parcela.ref.par + "" + parcela.ref.rec
            }
          </Typography>

          <Typography paragraph variant="h6" color="text.secondary">
            <Box component="span" fontWeight='fontWeightMedium' color="text.primary">Área</Box>: {
              parcelaInfo != null ? getArea() : ''
            }
          </Typography>

          <Typography paragraph variant="h6" color="text.secondary">
            <Box component="span" fontWeight='fontWeightMedium' color="text.primary">Provincia</Box>: {
              parcelaInfo != null ? parcelaInfo.parcelaInfo.provincia : ''
            }
          </Typography>

          <Typography paragraph variant="h6" color="text.secondary">
            <Box component="span" fontWeight='fontWeightMedium' color="text.primary">Municipio</Box>: {
              parcelaInfo != null ? parcelaInfo.parcelaInfo.municipio : ''
            }
          </Typography>

          <Typography paragraph variant="h6" color="text.secondary">
            <Box component="span" fontWeight='fontWeightMedium' color="text.primary">Uso SIGPAC</Box>: {
              parcelaInfo != null ? parcelaInfo.query[0].uso_sigpac : ''
            }
          </Typography>

          <Typography paragraph variant="h6" color="text.secondary">
            <Box component="span" fontWeight='fontWeightMedium' color="text.primary">Fecha ortofoto</Box>: {
              parcelaInfo != null ? moment(parcelaInfo.convergencia.cat_fechaultimaconv).format('DD/MM/YYYY') : ''
            }
          </Typography>

        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Cancelar
          </Button>
          <Button onClick={handleAddParcela} color="primary" autoFocus>
            Añadir
          </Button>
        </DialogActions>


      </Dialog>
    </div>
  );
}
