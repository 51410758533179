import React, { useEffect } from 'react'
import UserService from '../../services/user/userService';
import axios from 'axios';
import NotificationsIcon from '@mui/icons-material/Notifications';
import {Typography} from '@mui/material'
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';




export default function NumNotificactions() {
    const [numNotifications, setNumNotifications] = React.useState(0);
    const { getUserInfo } = UserService();
    const params = {
        username: getUserInfo().username
    }

    function getNotifications() {
        axios.get(process.env.REACT_APP_API_BASE_URL + '/notifications/getUnreaded', { params })
            .then(res => {
                if (res.data.notifications.length !== numNotifications) {
                    setNumNotifications(res.data.notifications.length);
                }
            })
            .catch(err => {
                console.log(err);
            })
    }

    useEffect(() => {
        //get notifications from db every minute
        const interval = setInterval(getNotifications, 60000);
        getNotifications();
        return () => {
            clearInterval(interval);
        };
    }, []);

    return (
        <div>

            <Typography component="h2" variant="h4"  gutterBottom>
                Notificaciones
            </Typography>
            <NotificationsIcon fontSize='large' color='primary'/>
            <Typography component="p" variant="h4">
                {numNotifications}
            </Typography>
            <Button sx={{mt: 2}}variant="contained" color="primary" component={Link} to="/notifications" >
                Ver Notificaciones
            </Button>

        </div>
    )
}
