import MUIDataTable from "mui-datatables";
import React from "react";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import axios from "axios";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import AddUser from "./AddUser";
import EditUser from "./EditUser";
import UserService from '../../services/user/userService';
import { useSnackbar } from 'notistack';

const muiCache = createCache({
    key: "mui-datatables",
    prepend: true
});

export default function Users() {
    const { getUserInfo } = UserService();
    const { enqueueSnackbar } = useSnackbar();
    const [data, setData] = React.useState([]);
    const [openAddUser, setOpenAddUser] = React.useState(false);
    const [openEditUser, setOpenEditUser] = React.useState(false);
    const [selectedUser, setSelectedUser] = React.useState({});

    function getData() {
        axios.get(process.env.REACT_APP_API_BASE_URL + "/users").then(res => {
            const json = res.data;
            //json to array
            const data = Object.keys(json).map(key => {
                return [
                    json[key].name,
                    json[key].username,
                    json[key].email,
                    json[key].role,
                ];
            });

            //remove current user
            const user = getUserInfo();
            data.splice(data.findIndex(x => x[1] === user.username), 1);
            setData(data);

        }
        ).catch(err => {
            console.log(err);
        });
    }

    React.useEffect(() => {
        getData();
    }, []);


    const columns = [
        { name: "Nombre", options: { filterOptions: { fullWidth: true } } },
        "Username",
        "Email",
        "Rol",
        { name: "Editar", 
        options: { 
            filter: false,
            sort: false,
            empty: true,
            print: false,
            download: false,
            customBodyRender: (value, tableMeta, updateValue) => {
                return (
                    <Tooltip title="Editar">
                        <IconButton onClick={() => {
                            setOpenEditUser(true);
                            setSelectedUser(data[tableMeta.rowIndex]);
                            console.log(data[tableMeta.rowIndex]);

                        }}>
                            <EditIcon />
                        </IconButton>
                    </Tooltip>
                );
            }
        
        } 
        
    }
    ];

    const options = {
        search: true,
        download: true,
        print: true,
        viewColumns: true,
        filter: true,
        filterType: "dropdown",
        responsive: "simple",
        tableBodyHeight: "100%",
        downloadOptions: {
            filename: "AgroInfoUsers" + new Date().toLocaleDateString() + ".csv",
            separator: ",",
            filterOptions: {
                useDisplayedColumnsOnly: true,
                useDisplayedRowsOnly: true,
            },
        },

        textLabels: {
            body: {
                noMatch: "No se encontraron resultados",
                toolTip: "Ordenar",
                columnHeaderTooltip: column => `Ordenar por ${column.label}`
            },
            pagination: {
                next: "Siguiente",
                previous: "Anterior",
                rowsPerPage: "Filas por página:",
                displayRows: "de",
                of: "de"
            },
            toolbar: {
                search: "Buscar",
                downloadCsv: "Descargar CSV",
                print: "Imprimir",
                viewColumns: "Ver columnas",
                filterTable: "Filtrar tabla"
            },
            filter: {
                all: "Todos",
                title: "Filtros",
                reset: "Reiniciar"
            },
            viewColumns: {
                title: "Ver columnas",
                titleAria: "Ver/Ocultar columnas"
            },
            selectedRows: {
                text: "fila(s) seleccionada(s)",
                delete: "Eliminar",
                deleteAria: "Eliminar fila seleccionada"
            }

            
        },
        


        onRowsDelete: (e) => { deleteUsers(e.data); },


        customToolbar: () => {
            return (
                <>
                    <Tooltip title="Agregar usuario">
                        <IconButton >
                            <AddIcon sx={{
                                //on hover primary color
                                color: "primary",
                                "&:hover": {
                                    color: "primary.main"
                                }
                            }}
                                onClick={() => setOpenAddUser(true)}

                            />
                        </IconButton>
                    </Tooltip>

                </>
            );
        },






    };

    function deleteUsers(users) {
        console.log("data");
        console.log(data);
        users.forEach(element => {
            console.log(element);
            axios.delete(process.env.REACT_APP_API_BASE_URL + "/users/" + data[element.dataIndex][1]).then(res => {
                enqueueSnackbar("Usuario eliminado", {
                    variant: "success",
                    autoHideDuration: 2000,
                });
                getData();
            }
            ).catch(err => {
                enqueueSnackbar("Error al eliminar usuario " + data[element.dataIndex][1], {
                    variant: "error",
                    autoHideDuration: 2000,
                });
                console.log(err);
            }
            );
        });
        getData();
    }

    function closeAddUser() {
        setOpenAddUser(false);
    }

    function closeEditUser() {
        setOpenEditUser(false);
    }

    return (
        <CacheProvider value={muiCache}>
            <MUIDataTable
                title={"Lista de usuarios"}
                data={data}
                columns={columns}
                options={options}
            />
            <AddUser open={openAddUser} onClose={closeAddUser} getData={getData} />
            <EditUser open={openEditUser} onClose={closeEditUser} getData={getData} user={selectedUser}/>
        </CacheProvider>
    );
}

