import { Grid, Typography } from '@mui/material'
import React from 'react'

export default function PageNotFound() {
    return (
        <div>
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
            >

                <Typography paragraph variant="h1">Donde vas!</Typography>




                <img
                    src={('https://cdn.thinglink.me/api/image/2ufhwNgM3qHKGxq8wdcnpP8eBRRku1j1sBXTkqeRJDXVwjw64quk6UfmAShMngoXz2FonD7r5/10/10/none')}
                    alt='404'
                    width="70%"
                />

            </Grid>
        </div >
    )
}
