import axios from 'axios';
import { useSnackbar } from 'notistack';

const AxiosInterceptorsSetup = (navigate) => {
    const { enqueueSnackbar } = useSnackbar();
    axios.interceptors.response.use(undefined, err => {
        const error = err.response;
        // if error is 401 
        if (error.status === 401 && error.config &&
            !error.config.__isRetryRequest) {
            // request for a new token
            /*return getAuthToken().then(response => {
             // update the error config with new token
             error.config.__isRetryRequest = true;
             error.config.headers.token= localStorage.getItem("accessToken");
             return client(error.config);
            });*/
            error.config.__isRetryRequest = true;
            error.config.headers.token = "FALSETOKEN";
            return axios(error.config);
        } else {
            localStorage.removeItem("accessToken");
            navigate('/login');
            enqueueSnackbar('Sesión expirada, inicie sesión nuevamente', {
                variant: 'error',
                preventDuplicate: true,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                },
            });
            return Promise.reject(err);
        }
    });

    axios.interceptors.request.use(
        request => {
            const accessToken = JSON.parse(localStorage.getItem('accessToken'));
            if (accessToken) {
                request.headers['x-access-token'] = accessToken;
            }

            return request;
        },
        error => {
            return Promise.reject(error);
        }
    )
};

export { AxiosInterceptorsSetup };
