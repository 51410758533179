import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { SnackbarProvider } from 'notistack';

ReactDOM.render(
    <SnackbarProvider maxSnack={3}>
        <App />
    </SnackbarProvider>,
    document.getElementById('root'));
serviceWorkerRegistration.register();
