import * as React from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Chart from './Chart';
import TempHum from './TempHum';
import { Button, CardMedia, Typography, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import MapIcon from '@mui/icons-material/Map';
import SensorData from './SensorData';
import moment from 'moment';


function DashboardContent(props) {
    const imageUrl = "https://geoserver.agro-data.es/parcelas/previsualizacionParcela/" +
        props.parcela.ref.prov + "/" +
        props.parcela.ref.mun + "/" +
        props.parcela.ref.agr + "/" +
        props.parcela.ref.zona + "/" +
        props.parcela.ref.pol + "/" +
        props.parcela.ref.par + "/" +
        props.parcela.ref.rec + "/";

    const ref = props.parcela.ref.prov + props.parcela.ref.mun + props.parcela.ref.agr + props.parcela.ref.zona + props.parcela.ref.pol + props.parcela.ref.par + props.parcela.ref.rec;



    return (

        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
                {/* Title */}
                <Grid item xs={12} md={12} lg={12}>
                    <Paper
                        sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Grid container spacing={3} columnSpacing={6} justifyContent='space-between'>
                            <Grid item xs={12} md={12} lg={12} textAlign='center'>
                                <Typography variant="h4" component="h1" gutterBottom color={'primary'}>
                                    {props.parcela.name}
                                </Typography>
                            </Grid>

                            <Grid item xs={12} md={6} lg={6}>
                                <CardMedia
                                    component="img"
                                    image={imageUrl}
                                    title={props.parcela.name}
                                    alt={props.parcela.name}
                                />

                                <Typography variant="h6" component="h1" gutterBottom color={'text.secondary'}>
                                    <Box component="span" fontWeight='fontWeightMedium' color="text.primary">Fecha Captura</Box>: {
                                        //Date format
                                        moment(props.parcela.fechaOrtofoto).format('DD/MM/YYYY')
                                        }
                                </Typography>


                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <Typography paragraph variant="h5" color="text.secondary">
                                    <Box component="span" fontWeight='fontWeightMedium' color="text.primary">Provincia:</Box> {props.parcela.provincia}
                                </Typography>

                                <Typography paragraph variant="h5" color="text.secondary">
                                    <Box component="span" fontWeight='fontWeightMedium' color="text.primary">Municipio</Box> {props.parcela.municipio}
                                </Typography>

                                <Typography paragraph variant="h5" color="text.secondary">
                                    <Box component="span" fontWeight='fontWeightMedium' color="text.primary">Referencia:</Box> {ref}
                                </Typography>

                                <Typography paragraph variant="h5" color="text.secondary" >
                                    <Box component="span" fontWeight='fontWeightMedium' color="text.primary">Área:</Box> {
                                        props.parcela.surface >= 10000 ? (props.parcela.surface / 10000).toFixed(2) + " ha" : props.parcela.surface.toFixed(2) + " m2"
                                    }
                                </Typography>

                                <Typography variant="h5" color="text.secondary" sx={{mb:2}}>
                                    <Box component="span" fontWeight='fontWeightMedium' color="text.primary">Uso SIGPAC:</Box> {props.parcela.uso_sigpac}
                                </Typography>

                                <SensorData parcela={props.parcela} />


                            </Grid>
                        </Grid>

                        <Button sx={{ mt: 2 }} variant="contained" color="primary" startIcon={<MapIcon />} component={Link} to={"/map/" + props.parcela.coordenadas.lon + "/" + props.parcela.coordenadas.lat}>
                            Ver en Mapa
                        </Button>

                    </Paper>
                </Grid>


                {/* Chart */}
                <Grid item xs={12} md={8} lg={9}>
                    <Paper
                        sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            height: 240,
                        }}
                    >
                        <Chart />
                    </Paper>
                </Grid>
                {/* Recent Deposits */}
                <Grid item xs={12} md={4} lg={3}>
                    <Paper
                        sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            height: 240,
                        }}
                    >
                        <TempHum />
                    </Paper>
                </Grid>
            </Grid>
        </Container>

    );
}

export default function Dashboard(props) {
    return <DashboardContent parcela={props.parcela} />;
}