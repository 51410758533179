import React from 'react'
import { useState, useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import AuthService from '../../services/auth/authService';
import LoginBase from './LoginBase';
import Box from '@mui/material/Box';
import jwtDecode from 'jwt-decode';
import LoadingIcon from './LoadingIcon';

export default function ResetPassword() {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const { setNewPassword } = AuthService();
    const [error, setError] = useState(false);
    const [searchParams] = useSearchParams();

    useEffect(() => {
        const token = searchParams.get('token');
        try {
            if (token === null || jwtDecode(token).exp < Date.now() / 1000) {
                throw new Error('Token expired or invalid');
            }
        } catch (error) {
            enqueueSnackbar("Enlace inválido, solicite uno nuevo desde restablecer contraseña", {
                variant: 'error',
                preventDuplicate: true,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                },
            });
            navigate('/login');
        }
    });

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const token = searchParams.get('token');
        console.log(searchParams.get('token'));
        const credentials = {
            pass: data.get('password'),
            passRepeat: data.get('password-repeat'),
        }
        if (credentials.pass === '' || credentials.passRepeat === '') {
            setError(true);
            return;
        } else {
            setError(false);
        }

        if (credentials.pass !== credentials.passRepeat) {
            enqueueSnackbar('Las contraseñas no coinciden', {
                variant: 'error',
                preventDuplicate: true,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                },
            });
            return;
        }
        setNewPassword(credentials.pass, token).then(
            () => {
                enqueueSnackbar('Contraseña restablecida', {
                    variant: 'success',
                    preventDuplicate: true,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'center',
                    }
                });
                navigate('/login');
            },
            (error) => {
                //Show notistack error notification
                enqueueSnackbar("Parece que algo va mal, prueba de nuevo más tarde", {
                    variant: 'error',
                    preventDuplicate: true,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'center',
                    },
                });
            }
        );

    }

    return (
        <LoginBase>

            <LoadingIcon loading={false} />
            <Typography component="h1" variant="h5" align="center" >
                Elige tu nueva contraseña
            </Typography>

            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                <TextField
                    margin="normal"
                    fullWidth
                    name="password"
                    label="Nueva contraseña"
                    type="password"
                    id="password"
                    autoComplete="new-password"
                    error={error}
                    helperText={error ? 'Rellene todos los campos' : ''}

                />
                <TextField
                    margin="normal"
                    fullWidth
                    name="password-repeat"
                    label="Confirmar contraseña"
                    type="password"
                    id="password"
                    autoComplete="new-password"
                    error={error}

                />

                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className="submit"
                    sx={{ mt: 3, mb: 2 }}
                >
                    Cambiar contraseña
                </Button>
            </Box>
        </LoginBase>

    );
}

