import React, { useEffect} from 'react'
import axios from 'axios';
import UserService from '../../services/user/userService';
import { Stack } from '@mui/material';
import Notification from './Notification';

export default function Notifications() {
    const [notifications, setNotifications] = React.useState([]);
    const { getUserInfo } = UserService();
    //get notifications from db every minute
    const params = {
        username: getUserInfo().username
    }

    function getNotifications() {
        axios.get(process.env.REACT_APP_API_BASE_URL + '/notifications/get', { params })
            .then(res => {
                setNotifications(res.data.notifications);
            })
            .catch(err => {
                console.log(err);
            });
    }

    useEffect(() => {
        getNotifications();
        return () => {
            setNotifications([]);
        }
    }, []);

    return (
        // Display a Notification for each notification
        <div>
            <Stack 
            spacing={2} 
            direction='column'
            alignItems='center'
            >
            
            {notifications.map(notification => {
                return (
                    <Notification key={notification._id} notification={notification} />
                )
            })}
            </Stack>
        </div>
    )
}
