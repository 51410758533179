import React from 'react'
import { useEffect } from 'react'
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import '../../assets/styles/animate.css';
import { ReactComponent as LogoOscuro } from '../../assets/images/agroInfoOscuro.svg';
import { ReactComponent as LogoClaro } from '../../assets/images/agroInfoClaro.svg';



export default function AppBarLogo(props) {

    const theme = useTheme();

    function animateOnClick() {
        document.getElementById("eB8lyM6F9GT1").onmouseup = function () {
            console.log("Working1");
            document.getElementById("eB8lyM6F9GT3_tr").style.animation = 'eB8lyM6F9GT3_tr__tr 1700ms linear 1 normal forwards';
        };

        document.getElementById("eB8lyM6F9GT1").onmousedown = function () {
            console.log("Working1");
            document.getElementById("eB8lyM6F9GT3_tr").style.animation = '';
        };
    }

    useEffect(() => {
        animateOnClick();

    })

    return (

        <Grid container direction={"column"} style={{ maxHeight: '50px', maxWidth: '100%' }}>
            <Grid item xs={3}>
                    <LogoOscuro style={{ width: "200px", height: "50px" }} alt="Logo" /> 
            </Grid>
        </Grid>


    )
}
