import React from 'react';
import { AxiosInterceptorsSetup } from '../services/auth/authInterceptor';
import { Route, Navigate, Routes } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

function AxiosInterceptorNavigate() {
    AxiosInterceptorsSetup(useNavigate());
    return <></>;
  }

export default function PrivateRoute(props) {
    const accessToken = JSON.parse(localStorage.getItem('accessToken'));
    const { component: Component, ...rest } = props;
    
    if (accessToken) {
        return (
            <>
            {<AxiosInterceptorNavigate />}
            <Routes>
                <Route {...rest} render={(props) =>
                    (<Component {...props} />)
                }
                />

            </Routes>
            </>
        )
    }
    //redirect if there is no user 
    return <Navigate to='/login' />
}