import axios from 'axios';

export default function AuthService() {

    //register user
    const registerUser = async (data) => {
        const { username, email, password, passwordConfirm } = data;
        return axios.post(`auth/register`, {
            username, email, password, passwordConfirm
        }).catch((err) => {
            console.log(err);
        })
    };

    const requestNewPassword = async (data) => {
        const { username } = data;
        console.log(username);
        return axios.post(process.env.REACT_APP_API_BASE_URL + '/reset-password/recover', {
            username
        });
    };

    const setNewPassword = async (password, token) => {
        return axios.post(process.env.REACT_APP_API_BASE_URL + '/reset-password', {
            password, token
        });
    };

    //login user
    const loginUser = async (credentials) => {


        const { username, password, keepSession } = credentials;

        //Using create to avoid the request uses the interceptor
        return axios.create().post(process.env.REACT_APP_API_BASE_URL + '/auth', {
            username,
            password,
            keepSession
        }).then(async (response) => {
            if (response.data.token) {
                localStorage.setItem("accessToken", JSON.stringify(response.data.token));
            }

            return response.data;
        })
    };

    const logout = () => {
        localStorage.removeItem('accessToken');

    };

    return {
        registerUser,
        loginUser,
        requestNewPassword,
        setNewPassword,
        logout
    }
}


