import 'ol/ol.css';
import React, { useRef, useState, useEffect } from "react"
import OlMap from "ol/Map";
import OlSourceOSM from 'ol/source/OSM';
import OlLayerTile from 'ol/layer/Tile';
import OlView from 'ol/View';
import GeoJSON from "ol/format/GeoJSON";
import { fromLonLat } from "ol/proj";
import VectorTileLayer from 'ol/layer/VectorTile';
import VectorTileSource from 'ol/source/VectorTile';
import * as MapUtils from './mapUtils.js';
import TileLayer from 'ol/layer/Tile';
import XYZ from 'ol/source/XYZ';
import { Grow, SpeedDialAction, SpeedDialIcon } from '@mui/material';
import TileWMS from 'ol/source/TileWMS';
import DialogAddParcela from './DialogAddParcela';
import { MapboxVectorLayer } from 'ol-mapbox-style';
import { useTheme } from '@mui/material/styles';
import UserService from '../../services/user/userService';
import SearchLocation from './SearchLocation';
import { addMeasureTool, removeMeasureTool } from './measureTool';
import '../../assets/styles/map/ol-tooltip.css';

import Dial from './Dial';
import LayerSelector from './LayerSelector.jsx';
import { useParams } from 'react-router-dom';

const Map = ({ children, zoom, center }) => {
  const { getUserInfo } = UserService();
  const userInfo = getUserInfo();
  const mapRef = useRef();
  const [openAddParcela, setOpenAddParcela] = useState(false);
  const [parcela, setParcela] = useState(null);
  const [map, setMap] = useState(null);
  const theme = useTheme();
  const isMeasuring = useRef(false);
  const [mapDetailLevel, setMapDetailLevel] = useState(null);
  const params = useParams();




  const closeAddParcela = () => {
    setOpenAddParcela(false);
  }

  const focusLocation = (coordinates) => {
    map.getView().animate({
      center: fromLonLat(coordinates),
      duration: 1000,
      zoom: 15
    });

  }

  function openMeasureLineal() {
    if (map != null) {
      addMeasureTool(map, theme.palette.mode, 'distance');
      isMeasuring.current = true;
    }
  }

  function openMeasureSurface() {
    if (map != null) {
      addMeasureTool(map, theme.palette.mode, 'area');
      isMeasuring.current = true;
    }
  }

  function closeMeasure() {
    removeMeasureTool(map);
    isMeasuring.current = false;
  }

  /**
   * Low Detail
   * Layers -> baseMap, tileOrto, vectorTileSigpac, selectionLayerSigpac
   */

  function setLowDetail() {
    setMapDetailLevel('low');

    map.getLayers().getArray().slice().forEach(layer => {
      if (layer && (layer.get('name') === 'IGN Base') || layer.get('mapbox-source') === 'contours' || layer.get('mapbox-source') === 'Parajes') {
        map.removeLayer(layer);
      }
    });
  }

  /**
   * Medium Detail
   * Layers -> baseMap, tileOrto, vectorTileSigpac, selectionLayerSigpac, tileBaseIGN, tileParajes
   */

  function setMediumDetail() {
    setMapDetailLevel('medium');

    map.getLayers().getArray().slice().forEach(layer => {
      if (layer && (layer.get('mapbox-source') === 'contours')) {
        map.removeLayer(layer);
      }
    });

    if (mapDetailLevel === 'low') {
      map.addLayer(tileParajes)
      map.addLayer(tileBaseIGN)
    }
  }

  /**
   * Full Detail
   * Layers -> baseMap, contours, tileOrto, vectorTileSigpac, selectionLayerSigpac, tileBaseIGN, tileParajes
   */

  function setFullDetail() {
    setMapDetailLevel('high');

    if (mapDetailLevel === 'low') {
      map.addLayer(contours)
      map.addLayer(tileParajes)
      map.addLayer(tileBaseIGN)
    } else if (mapDetailLevel === 'medium') {
      map.addLayer(contours)
    }
  }

  //CAPAS

  const darkBaseMap = new MapboxVectorLayer({
    styleUrl: 'https://api.maptiler.com/maps/9f0366d5-1d85-412a-80d1-532da1c320ef/style.json?key=' + process.env.REACT_APP_MAPTILER_API_KEY,
    maxZoom: 16
  });

  const contours = new MapboxVectorLayer({
    styleUrl: 'https://api.maptiler.com/maps/9312c27c-5627-4b4a-b24f-6c9db5014dc7/style.json?key=' + process.env.REACT_APP_MAPTILER_API_KEY,
    minZoom: 14,
    maxZoom: 16,
    source: "contours",
  });

  /**
   * SIGPAC tile -> Recinto parcelas 
   * Vector orientado a contener formas que dependan del área visible en el mapa. Se cargan dependiendo de la zona visible.
   */
  const vectorTileSigpac = new VectorTileLayer({
    style: MapUtils.unselectedParcelStyle,
    source: new VectorTileSource({
      attributions: 'AgroData <a href="https://www.agro-data.es"' +
        ' target="_blank">©AgroData</a>.',
      format: new GeoJSON({
        featureProjection: "EPSG:3857"
      }),
      url: MapUtils.VECTOR_PARCEL_TILE_URL,
      maxZoom: 15
    })
  });

  /**
   * Ortofoto IGN tile -> Teselas WMTS 
   * Vector orientado a contener formas que dependan del área visible en el mapa. Se cargan dependiendo de la tesela visible.
   */

  const tileOrto = new TileLayer({
    preload: Infinity,
    source: new XYZ({
      attributions: 'Instituto Geográfico Nacional <a href="https://www.ign.es/web/ign/portal/inicio"' +
        ' target="_blank">©IGN</a>.',
      url: MapUtils.ORTO_TILE_URL
    }),
    minZoom: 16
  });

  /**
   * WMTS mapa base IGN
   */

  const tileBaseIGN = new TileLayer({
    name: 'IGN Base',
    preload: Infinity,
    source: new XYZ({
      attributions: 'SIGPAC <a href="https://www.mapa.gob.es/es/agricultura/temas/sistema-de-informacion-geografica-de-parcelas-agricolas-sigpac-/default.aspx"' +
        ' target="_blank">©MAPA</a>.',
      url: MapUtils.IGN_BASE_URL,
    }),
    minZoom: 16

  })

  /**
   * WMS parajes
   */

  const tileParajes = new TileLayer({
    name: 'IGN Base',
    source: new TileWMS({
      url: MapUtils.IGN_PARAJES_URL,
      params: { 'LAYERS': 'GN.GeographicalNames', 'STYLES': 'nombresgeograficos', 'FORMAT': 'image/svg+xml' },
      serverType: 'geoserver'
    }),
    minZoom: 14

  })

  /**
   * Mapa base OSM
   */

  const osm = new OlLayerTile({
    preload: Infinity,
    source: new OlSourceOSM({
      opaque: false
    }),
    maxZoom: 16
  });

  // on component mount
  useEffect(() => {
    // Parcela seleccionada
    let selectedParcel = {};

    /**
     * Parcela seleccionada, a la cual se le aplica otro estilo
     */

    const selectionLayerSigpac = new VectorTileLayer({
      source: vectorTileSigpac.getSource(),
      style: function (feature) {
        if (feature.getId() in selectedParcel) {
          return MapUtils.selectedParcelStyle;
        }
      }

    });

    /**
     * Objeto mapa (con sus capas) objeto del renderizado
     */

    const baseMap = theme.palette.mode === "dark" ? darkBaseMap : osm;

    const mapObject = new OlMap({
      //if params.lon exists, it will be used as the center of the map
      view: new OlView({
        center: fromLonLat([params.lon || -5.272424, params.lat || 42.597225]),
        zoom: params.lon ? 17 : 15,
        maxZoom: 19,
      }),
      target: mapRef.current,
      layers: [baseMap, contours, tileOrto, vectorTileSigpac, selectionLayerSigpac, tileBaseIGN, tileParajes]
    });

    setMap(mapObject);

    /**
     * Obtener ubicación del usuario
     */
    if (navigator.geolocation && !params.lon) {
      navigator.geolocation.getCurrentPosition(function (position) {
        var lat = position.coords.latitude;
        var lon = position.coords.longitude;

        mapObject.getView().setCenter(fromLonLat([lon, lat]));
      });
    }


    //EVENTOS

    /**
     * Evento click sobre una parcela
      */

    mapObject.on('click', function (event) {

      if (!isMeasuring.current) {

        vectorTileSigpac.getFeatures(event.pixel).then(function (features) {
          const feature = features[0];
          if (!feature) {
            return;
          }

          if (feature) {
            let selectedParcela = {
              owner: userInfo.username,
              name: "Parcela",
              ref: {
                prov: feature.get('provincia'),
                mun: feature.get('municipio'),
                agr: feature.get('agregado'),
                zona: feature.get('zona'),
                pol: feature.get('poligono'),
                par: feature.get('parcela'),
                rec: 1,
              }
            }
            updateSelectedParcel(feature);
            setParcela(selectedParcela);
            setOpenAddParcela(true);
            //TODO agregar resto de datos
          }
        });
      }
    });


    /**
     * hover -> Cambiar estilo parcela seleccionada
     */

    mapObject.on("pointermove", function (event) {
      if (!isMeasuring.current) {
        vectorTileSigpac.getFeatures(event.pixel).then(function (features) {
          const feature = features[0];
          if (!feature) {
            mapObject.getTarget().style.cursor = '';
            return;
          }
          // Cambiamos el cursor al pasar sobre el elemento
          mapObject.getTarget().style.cursor = 'pointer';
          updateSelectedParcel(feature);
        });
      }
    });

    function updateSelectedParcel(feature) {
      const fid = feature.getId();
      //removes the previous selected parcel
      selectedParcel = {};
      // add selected feature to lookup
      selectedParcel[fid] = feature;
      selectionLayerSigpac.changed();
    }

  }, []);

  return (
    <Grow in>
      <div>
        <DialogAddParcela parcela={parcela} open={openAddParcela} onClose={closeAddParcela} />
        <div ref={mapRef} className="ol-map" style={{ position: "relative", width: "auto", height: "80vh" }}>
          <SearchLocation focusLocation={focusLocation} />
          <Dial openMeasureLineal={openMeasureLineal} openMeasureSurface={openMeasureSurface} closeMeasure={closeMeasure} />
          <LayerSelector setMediumDetail={setMediumDetail} setFullDetail={setFullDetail} setLowDetail={setLowDetail} />
        </div>
      </div>
    </Grow >

  )
}

export default Map;