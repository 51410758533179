import React from 'react'
import { useEffect } from 'react'
import Grid from '@mui/material/Grid';
import { ReactComponent as AnimatedLogo } from '../../assets/images/agroInfoIcon.svg';
import { ReactComponent as TextLogoOscuro } from '../../assets/images/agroInfoTextOscuro.svg';
import { ReactComponent as TextLogoClaro } from '../../assets/images/agroInfoTextOscuro.svg';
import '../../assets/styles/animate.css';
import { useTheme } from '@mui/system';
import { useState } from 'react';

export default function LoadingIcon(props) {

    const loading = props.loading;
    const theme = useTheme();
    const [isInit, setIsInit] = useState(true);

    function startAnimateSvg() {
        console.log("Working1");
        document.getElementById("eB8lyM6F9GT3_tr").style.animation = "eB8lyM6F9GT3_tr__tr 1700ms linear infinite normal forwards";
    }
    
    const stopAnimateEvent = function () {
        document.getElementById("eB8lyM6F9GT3_tr").removeEventListener('animationiteration', stopAnimateEvent);
        console.log("Working2");
        document.getElementById("eB8lyM6F9GT3_tr").style.animation = "";
    };

    function stopAnimateSvg() {
        document.getElementById("eB8lyM6F9GT3_tr").addEventListener('animationiteration', stopAnimateEvent);
    }

    function animateOnClick() {
        document.getElementById("eB8lyM6F9GT1").onmouseup = function () {
            console.log("Working3");
            document.getElementById("eB8lyM6F9GT3_tr").style.animation = 'eB8lyM6F9GT3_tr__tr 1700ms linear 1 normal forwards';
        };
        document.getElementById("eB8lyM6F9GT1").onmousedown = function () {
            console.log("Working4");
            document.getElementById("eB8lyM6F9GT3_tr").style.animation = '';
        };
    }

    useEffect(() => {

        animateOnClick();

        if (loading) {
            startAnimateSvg();
        } else if (!loading && !isInit) {
            stopAnimateSvg();
        } else if (isInit) {
            setIsInit(false);
        }

    }, [loading])

    return (
        <div>
            <Grid container textAlign="center" alignItems="center" direction={"column"}>
                <Grid item xs={3}>
                    <AnimatedLogo width={"150px"} />
                </Grid>

                <Grid item xs={3} textAlign="center" sx={{ mb: 5 }}>
                    {theme.palette.mode === "dark" ?
                        <TextLogoOscuro width={"245px"} /> :
                        <TextLogoClaro width={"245px"} />
                    }
                </Grid>
            </Grid>
        </div>
    )
}
