import { Fill, Stroke, Style } from 'ol/style';
export const VECTOR_PARCEL_TILE_URL = process.env.REACT_APP_API_BASE_URL + "/layers/vectores/sigpacParcelas/epsg@3857/{z}.{x}.{-y}.geojson";
export const SIGPAC_RECINFO_URL = process.env.REACT_APP_API_BASE_URL + "/layers/vectores/sigpacParcelas/recinfo/{prov}/{mun}/{agr}/{zona}/{pol}/{par}/{rec}.geojson"
export const ORTO_TILE_URL = "https://www.ign.es/wmts/pnoa-ma?layer=OI.OrthoimageCoverage&style=default&tilematrixset=GoogleMapsCompatible&Service=WMTS&Request=GetTile&Version=1.0.0&Format=image%2Fjpeg&TileMatrix={z}&TileCol={x}&TileRow={y}";
export const IGN_BASE_URL = "https://www.ign.es/wmts/ign-base?layer=IGNBaseOrto&style=default&tilematrixset=GoogleMapsCompatible&Service=WMTS&Request=GetTile&Version=1.0.0&Format=image/png&TileMatrix={z}&TileCol={x}&TileRow={y}";
export const IGN_PARAJES_URL = "https://www.ign.es/wms-inspire/ngbe";


export var unselectedParcelStyle = new Style({
  stroke: new Stroke({
      color: 'rgba(200,20,20,0.8)',
      width: 2,
    }),
    fill: new Fill({
      color: 'rgba(0,0,0,0)',
    }),
})

export var selectedParcelStyle = new Style({
    stroke: new Stroke({
        color: 'rgba(200,20,20,0.8)',
        width: 4,
      }),
      fill: new Fill({
        color: 'rgba(200,20,20,0.4)',
      }),
})



