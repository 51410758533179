import React, { useState, useEffect } from 'react'
import Map from "./Map/Map"
import SideBar from "./AppBar/AppBar"
import { createTheme, ThemeProvider } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Parcelas from './Parcelas/Parcelas';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import PageNotFound from './Login/PageNotFound';
import Login from './Login/Login';
import { AuthContext } from '../services/auth/authContext';
import PrivateRoute from './PrivateRoute';
import Users from './Users/Users';
import ForgottenPassword from './Login/ForgottenPassword';
import ResetPassword from './Login/ResetPassword';
import MyProfile from './MyProfile/MyProfile';
import Notifications from './Notifications/Notifications';
import ParcelaDetails from './Parcelas/ParcelaDetails';

export default function App() {

  // Tema
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const [dark, setDark] = useState(prefersDarkMode)

  const theme = createTheme({
    palette: {
      mode: dark ? 'dark' : 'light',
      primary: {
        main: dark ? '#F0C66C': '#0f5718',
      },
      secondary: {
        main: dark ? '#0f5718' : '#F0C66C',
      },
      black: '#000000',
    },

    input: {
      //no shadow on inputs
      boxShadow: 'none',
    },

  });

  //Cambiar el tema de la status bar al cambiar el tema.
  if (dark) {
    document.querySelector("meta[name='theme-color']").setAttribute("content", "#272727");
  } else {
    document.querySelector("meta[name='theme-color']").setAttribute("content", theme.palette.primary.main);
  }

  useEffect(() => {
    setDark(prefersDarkMode);
  }, [prefersDarkMode]);


  // Usuario
  const [user, setUser] = useState(null);

  // App view
  function AppView() {
    return (
      <SideBar auto toggleDark={() => setDark(!dark)}>
        <Routes>
          <Route exact path="/" element={<Parcelas />} />
          <Route exact path="/map/" element={<Map />} />
          <Route path="/map/:lon/:lat" element={<Map />} />
          <Route exact path="/users" element={<Users />} />
          <Route exact path="/profile" element={<MyProfile />} />
          <Route exact path="/notifications" element={<Notifications />} />
          <Route path="/parcela/:id" element={<ParcelaDetails />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </SideBar>
    )
  }

  //if offline return page not found
  if (!navigator.onLine) {
    return (
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <SideBar auto toggleDark={() => setDark(!dark)}>
            <Routes>
              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </SideBar>
        </ThemeProvider>
      </BrowserRouter>
    )
  }


  return (
    <AuthContext.Provider value={{ user, setUser }}>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/forgotten-password" element={<ForgottenPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="*" element={<PrivateRoute path="*" element={<AppView />} />} />
          </Routes>
        </ThemeProvider>
      </BrowserRouter>
    </AuthContext.Provider>
  )
}
