import * as React from 'react';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CardHeader from '@mui/material/CardHeader';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Delete from '@mui/icons-material/Delete';
import Edit from '@mui/icons-material/Edit';
import { ListItemIcon, ListItemText } from '@mui/material';
import { Box } from '@mui/system';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import SensorData from './SensorData';
import {Link as RouterLink} from 'react-router-dom';

export default function Parcela(props) {
    const { enqueueSnackbar } = useSnackbar();
    const [name, setName] = React.useState(props.parcela.name);
    const [open, setOpen] = React.useState(false);

    //console.log(props.parcela);

    const imageUrl = "https://geoserver.agro-data.es/parcelas/previsualizacionParcela/" +
        props.parcela.ref.prov + "/" +
        props.parcela.ref.mun + "/" +
        props.parcela.ref.agr + "/" +
        props.parcela.ref.zona + "/" +
        props.parcela.ref.pol + "/" +
        props.parcela.ref.par + "/" +
        props.parcela.ref.rec + "/";

    const ref = props.parcela.ref.prov + props.parcela.ref.mun + props.parcela.ref.agr + props.parcela.ref.zona + props.parcela.ref.pol + props.parcela.ref.par + props.parcela.ref.rec;

    const handleClickOpenDialog = () => {
        setOpen(true);
    };

    const handleCloseDialog = () => {
        setOpen(false);
    };


    //Menu de opciones
    const [anchorEl, setAnchorEl] = React.useState(null);
    const openMenu = Boolean(anchorEl);
    const handleClickMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    //Handle para cerrar el menu
    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    //Handle para eliminar la parcela
    const handleDeleteClick = () => {
        removeParcela(props.parcela._id);
        handleCloseMenu();
    }

    //Handle para editar la parcela
    const handleEditClick = () => {
        handleClickOpenDialog();
        handleCloseMenu();
    }

    const editParcela = async () => {
        props.parcela.name = name;
        var parcela = props.parcela;
        console.log(parcela);
        //send to server
        await axios.post(process.env.REACT_APP_API_BASE_URL + '/parcelas/edit', {
            _id: parcela._id,
            name: document.getElementById('name').value,
            ref: parcela.ref,
            surface: parcela.surface,
            uso_sigpac: parcela.uso_sigpac
        })

            .then(function (response) {
                //show succes notification
                enqueueSnackbar('Parcela editada correctamente', {
                    variant: 'success',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right',
                    },
                });
            })
            .catch(function (error) {
                //show error notification
                enqueueSnackbar('Error al editar la parcela', {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right',
                    },
                });
            });

        setName(document.getElementById('name').value);
        handleCloseDialog();
    }

    const removeParcela = async (id) => {

        await axios.post(process.env.REACT_APP_API_BASE_URL + '/parcelas/delete', {
            _id: id
        })
            .then(function (response) {
                //show succes notification
                enqueueSnackbar('Parcela eliminada correctamente', {
                    variant: 'success',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right',
                    },
                });
            })
            .catch(function (error) {
                //show error notification
                enqueueSnackbar('Error al eliminar la parcela', {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right',
                    },
                });
            });

        props.removeParcela(id);
    }


    const handleSubmit = (event) => {
        event.preventDefault();
        //editParcela();
    }


    return (
        <div>
            <>
                <Dialog open={open} onClose={handleCloseDialog} component="form" onSubmit={handleSubmit} fullWidth>
                    <DialogTitle>Editando: {name}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Introduce un nuevo nombre para la parcela
                        </DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label="Nombre"
                            type="text"
                            fullWidth
                            variant="standard"
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDialog}>Cancelar</Button>
                        <Button type="submit" onClick={editParcela}>Editar</Button>
                    </DialogActions>
                </Dialog>
            </>

            <Card elevation={4} style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column', height: '100%' }}>
                <CardHeader
                    action={
                        <>
                            <IconButton
                                aria-label="settings"
                                aria-controls="long-menu"
                                aria-expanded={open ? 'true' : undefined}
                                aria-haspopup="true"
                                onClick={handleClickMenu}>
                                <MoreVertIcon />
                            </IconButton>

                            <Menu
                                id="long-menu"
                                anchorEl={anchorEl}
                                open={openMenu}
                                onClose={handleCloseMenu}>

                                <MenuItem ey={"delete"} onClick={handleDeleteClick}>
                                    <ListItemIcon>
                                        <Delete fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText>Borrar</ListItemText>
                                </MenuItem>

                                <MenuItem key={"edit"} onClick={handleEditClick}>
                                    <ListItemIcon>
                                        <Edit fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText>Editar</ListItemText>
                                </MenuItem>
                            </Menu>
                        </>

                    }

                    titleTypographyProps={{ variant: 'h4' }}
                    title={name}
                    style={{ textAlign: 'center' }}

                />

                <CardMedia
                    component="img"
                    height="100%"
                    image={imageUrl}
                    alt={name}
                />
                <CardContent>
                    <Typography paragraph variant="h5" color="text.secondary">
                        <Box component="span" fontWeight='fontWeightMedium' color="text.primary">Provincia</Box>: {props.parcela.provincia}
                    </Typography>

                    <Typography paragraph variant="h5" color="text.secondary">
                        <Box component="span" fontWeight='fontWeightMedium' color="text.primary">Municipio</Box>: {props.parcela.municipio}
                    </Typography>

                    <Typography paragraph variant="h5" color="text.secondary">
                        <Box component="span" fontWeight='fontWeightMedium' color="text.primary">Referencia</Box>: {ref}
                    </Typography>

                    <Typography paragraph variant="h5" color="text.secondary" >
                        <Box component="span" fontWeight='fontWeightMedium' color="text.primary">Área</Box>: {
                            props.parcela.surface >= 10000 ? (props.parcela.surface / 10000).toFixed(2) + " ha" : props.parcela.surface.toFixed(2) + " m2"
                        }
                    </Typography>

                    <Typography variant="h6" color="text.secondary">
                        <Box component="span" fontWeight='fontWeightMedium' color="text.primary">Uso SIGPAC</Box>: {props.parcela.uso_sigpac}
                    </Typography>

                    {/* If there is sensor for this parcela, show info*/}
                    <SensorData parcela={props.parcela} />

                    {/* Button link to parcela/id*/}
                    <Button sx={{mt:2}} fullWidth variant="contained" color="primary" component={RouterLink} to={`/parcela/${props.parcela._id}`}>
                        Ver más
                    </Button>

                </CardContent >
            </Card>
        </div >
    );
}
