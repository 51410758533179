import OpacityIcon from '@mui/icons-material/Opacity';
import ThermostatIcon from '@mui/icons-material/Thermostat';
import React, { useState, useEffect } from 'react'
import Typography from '@mui/material/Typography';
import { Stack, Skeleton, Slide } from '@mui/material';
import { Box } from '@mui/system';
import axios from 'axios';
var moment = require('moment');

export default function SensorData(props) {

    const [sensorData, setSensorData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [lastUpdate, setLastUpdate] = useState(null);
    const [humidity, setHumidity] = useState(null);
    const [temperature, setTemperature] = useState(null);

    useEffect(() => {
        let interval;
        if (props.parcela.ttn_sensor_id) {
            getLastSensorData();
            interval = setInterval(getLastSensorData, 60000);
        }
        return () => {
            clearInterval(interval);
            setLoading(true);
        };
    }, []);

    function getLastSensorData() {
        axios.get(process.env.REACT_APP_API_BASE_URL + '/sensors/lastSensorData?device_id=' + props.parcela.ttn_sensor_id).then(res => {
            if (res.data) {
                setSensorData(res.data);
                const lastUpdate = getTimeSinceLastUpdate(new Date(res.data.received_at.S));
                //setLastUpdate(moment(lastUpdate).format('DD/MM/YYYY HH:mm:ss'));
                setLastUpdate(lastUpdate);
                setHumidity(roundTwoDecimals(res.data.humidity.N));
                setTemperature(roundTwoDecimals(res.data.temperature.N));
            }
            setLoading(false);
        });
    }

    function roundTwoDecimals(number) {
        return Math.round(number * 100) / 100;
    }

    function getTimeSinceLastUpdate(lastUpdate) {
        const now = new Date();
        const diff = now.getTime() - lastUpdate.getTime();
        const diffSeconds = Math.floor(diff / 1000);

        if (diffSeconds >= 3600) {
            const diffHours = Math.floor(diffSeconds / 3600);
            return diffHours > 1 ? diffHours + ' horas' : diffHours + ' hora';
        } else if (diffSeconds >= 60) {
            const diffMinutes = Math.floor(diffSeconds / 60);
            return diffMinutes > 1 ? diffMinutes + ' minutos' : diffMinutes + ' minuto';
        } else {
            return diffSeconds > 1 ? diffSeconds + ' segundos' : diffSeconds + ' segundo';
        }

    }


    if (props.parcela.ttn_sensor_id && !loading) {
        return (
            <div>
                <Stack
                    direction={{ xs: 'column', sm: 'row' }}
                    alignItems='left'
                    spacing={1}

                >
                    <Stack direction='row' alignItems='center' >
                        <OpacityIcon color="primary" fontSize='small' />
                        <Typography variant="h6" color="text.primary" style={{ marginRight: '8px' }}>
                            Hum.
                        </Typography>
                        <Typography variant="h6" color="text.primary">
                            {humidity}%
                        </Typography>
                    </Stack>
                    <Stack direction='row' alignItems='center'>

                        <ThermostatIcon color="primary" fontSize='small' />
                        <Typography variant="h6" color="text.primary" style={{ marginRight: '8px' }}>
                            Temp.
                        </Typography>
                        <Typography variant="h6" color="text.primary">
                            {temperature}ºC
                        </Typography>
                    </Stack>

                </Stack>
                <Typography variant="h7" color="text.secondary" style={{ marginLeft: 4 }}>
                    <Box component="span" fontWeight='fontWeightMedium' color="text.primary">Capturado hace</Box>
                    {lastUpdate ? `${lastUpdate}` : ' Sin info'}
                </Typography>
            </div>)
    } else if (props.parcela.ttn_sensor_id && loading) {
        return (
            <Skeleton animation="wave" variant="rect" width="100%" height={55} />
        );

    } else {
        return <></>;
    }
}