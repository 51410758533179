import * as React from 'react';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import PrintIcon from '@mui/icons-material/Print';
import ShareIcon from '@mui/icons-material/Share';
import SquareFootIcon from '@mui/icons-material/SquareFoot';
import Crop54Icon from '@mui/icons-material/Crop54';
import PanToolAltIcon from '@mui/icons-material/PanToolAlt';
import TuneIcon from '@mui/icons-material/Tune';

const actions = [
  { icon: <SquareFootIcon />, name: 'Medir longitud' },
  { icon: <Crop54Icon />, name: 'Medir área' },
  { icon: <PanToolAltIcon />, name: 'Desplazarse por el mapa' },
  { icon: <PrintIcon />, name: 'Print' },
  { icon: <ShareIcon />, name: 'Share' },
];

export default function BasicSpeedDial(props) {

  function handleOnClick(name) {
    if (name == 'Medir longitud') {
      props.openMeasureLineal();
    } else if (name == 'Medir área') {
      props.openMeasureSurface();
    } else if (name == 'Desplazarse por el mapa') {
      props.closeMeasure();
    }
  }

  return (
    <SpeedDial
      ariaLabel="SpeedDial basic example"
      sx={{ position: 'absolute', bottom: 40, right: 20 }}
      icon={<TuneIcon />}
    >
      {actions.map((action) => (
        <SpeedDialAction
          key={action.name}
          icon={action.icon}
          tooltipTitle={action.name}
          onClick={() => handleOnClick(action.name)}
        />
      ))}
    </SpeedDial>
  );
}


