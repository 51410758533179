import React, { useEffect } from 'react'
import axios from 'axios';
import MapIcon from '@mui/icons-material/Map';
import {Typography} from '@mui/material'
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';



export default function NumParcelas() {
    const [numParcelas, setNumParcelas] = React.useState(0);

    function getParcelas() {
        axios.get(process.env.REACT_APP_API_BASE_URL + '/parcelas/allByUser')
            .then(res => {
                if (res.data.parcelas.length !== numParcelas) {
                    setNumParcelas(res.data.parcelas.length);
                }
            })
            .catch(err => {
                console.log(err);
            });
    }

    useEffect(() => {
        //get notifications from db every minute
        const interval = setInterval(getParcelas, 60000);
        getParcelas();
        return () => {
            clearInterval(interval);
        };
    }, []);

    return (
        <div>
            <Typography component="h2" variant="h4" gutterBottom>
                Parcelas
            </Typography>
            <MapIcon fontSize='large' color='primary' />
            <Typography component="p" variant="h4">
                {numParcelas}
            </Typography>
            {/* <Link to='/parcelas'> */}
            < Button sx={{mt: 2}}variant="contained" color="primary" component={Link} to="/" >
                Ver Parcelas
            </Button>
        </div>
    )
}
