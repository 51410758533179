import { Badge, IconButton, Tooltip } from '@mui/material';
import React, { useEffect } from 'react'
import { useTheme } from '@mui/material/styles';
import NotificationsIcon from '@mui/icons-material/Notifications';
import axios from 'axios';
import { Link } from 'react-router-dom';
import UserService from '../../services/user/userService';

export default function NotificationsButton() {
    const theme = useTheme();
    const [numNotifications, setNumNotifications] = React.useState(0);
    const { getUserInfo } = UserService();
    //get notifications from db every minute
    const params = {
        username: getUserInfo().username
    }

    function getNotifications() {
        axios.get(process.env.REACT_APP_API_BASE_URL + '/notifications/getUnreaded', { params })
            .then(res => {
                if (res.data.notifications.length !== numNotifications) {
                    setNumNotifications(res.data.notifications.length);
                }
            })
            .catch(err => {
                console.log(err);
            })
    }

    useEffect(() => {
        //get notifications from db every minute
        const interval = setInterval(getNotifications, 60000);
        getNotifications();
        return () => {
            clearInterval(interval);
        };
    }, []);

    return (
        <div>
            <Tooltip title="Notificaciones">
                <IconButton aria-label="notifications" component={Link} to="/notifications" style={{color: 'white'}}>
                    <Badge badgeContent={numNotifications} color="primary">
                        <NotificationsIcon />
                    </Badge>
                </IconButton>
            </Tooltip>
        </div>
    )
}
