import jwt_decode from "jwt-decode";

export default function UserService() {

    //Get info about the logged user
    const getUserInfo = () => {
        const accessToken = JSON.parse(localStorage.getItem('accessToken'));
        const userInfo = jwt_decode(accessToken);
            
        return userInfo;
    }
    
    return {
        getUserInfo
    }
}