import { Box, Grid, Skeleton, Slide } from '@mui/material'
import React, { useState, useEffect } from 'react'
import Parcela from './Parcela'
import axios from 'axios';

export default function Parcelas(props) {
    const containerRef = React.useRef(null);
    //Get parcelas from db
    const [parcelas, setParcelas] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        axios.get(process.env.REACT_APP_API_BASE_URL + '/parcelas/allByUser/')
            .then(res => {
                setParcelas(res.data.parcelas);
                setLoading(false);
            });
        return () => {
            setParcelas([]);
            setLoading(true);
        };
    }, []);

    if (loading) {
        return (
            //skeleton simulating a list of parcels
            <Box ref={containerRef}>
                <Grid rowSpacing={2} columnSpacing={2} container >
                    {[...Array(4)].map((e, i) => (
                    <Grid key={i} item xs={12} sm={6} md={4} lg={3}>
                        <Skeleton animation="wave" variant="rect" width="100%" height={250} />
                    </Grid>
                    ))}
                </Grid>
            </Box>

        );

    } else if (parcelas.length === 0) {
        return (
            <div>
                <h2>No hay parcelas</h2>
            </div>
        )
    } else {

        return (
            <Grid rowSpacing={2} columnSpacing={2} container >
                {parcelas.map(parcela => (
                    <Slide key={parcela._id} direction="up" in container={containerRef.current}>
                        <Grid style={{display: 'flex'}} key={parcela._id} item xs={12} sm={6} md={4} lg={3}>
                            <Parcela  key={parcela._id} parcela={parcela} removeParcela={() => {
                                //remove parcela from parcelas array
                                setParcelas(parcelas.filter(p => p._id !== parcela._id));
                                console.log(parcelas);
                                }} />
                        </Grid>
                    </Slide>
                ))}
            </Grid>
        )
    }
}