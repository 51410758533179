import { Card, CardActionArea, Slide, Tooltip } from '@mui/material';
import { useEffect } from 'react';
import { useRef } from 'react';
import { useState } from 'react';
import '../../assets/styles/map/layer-selector.css';
import map from '../../assets/images/map/map.jpg';
import full from '../../assets/images/map/full.png';
import medium from '../../assets/images/map/medium.png';
import low from '../../assets/images/map/low.png';
export default function LayerSelector(props) {

    const [opened, setOpened] = useState(false);
    const mainCardRef = useRef(null);
    const [mapDetailLevel, setMapDetailLevel] = useState('full');

    return (
        <div className="div_size" style={{ position: 'absolute', bottom: 20, left: 20, overflow: 'hidden' }} ref={mainCardRef}>
            <Card id='layer_card' elevation={2} style={{ overflow: 'hidden' }}>
                <Tooltip title="Elegir nivel de detalle del mapa" placement="right">
                    <CardActionArea onClick={() => setOpened(!opened)}>
                        <img src={map} className="image_card" style={{ width: '70px', height: '70px' }} />
                    </CardActionArea>
                </Tooltip>
            </Card>

            <Slide direction="up" in={opened} mountOnEnter unmountOnExit>
                <Card elevation={2} className="sub_layer_card sub_position_1">
                    <Tooltip title="Se visualizan todas las capas disponibles" placement="right">
                        <CardActionArea onClick={() => { props.setFullDetail(); setMapDetailLevel('full'); setOpened(!opened) }}>
                            <img src={full} className="sub_image_card" style={{
                                width: '50px', height: '50px',
                                filter: mapDetailLevel === 'full' ? 'brightness(100%)' : 'brightness(70%)'
                            }} />
                        </CardActionArea>
                    </Tooltip>
                </Card>

            </Slide>

            <Slide direction="up" in={opened} mountOnEnter unmountOnExit>
                <div style={{ position: 'absolute', marginLeft: '80px', bottom: 80, zIndex: 3, color: 'gray' }}>
                    <h3 className='labelOutline'>Detallado</h3>
                </div>
            </Slide>

            <Slide direction="up" in={opened} mountOnEnter unmountOnExit>
                <Card elevation={2} className="sub_layer_card sub_position_2">
                    <Tooltip title="Sin curvas de nivel" placement="right">
                        <CardActionArea onClick={() => { props.setMediumDetail(); setMapDetailLevel('medium'); setOpened(!opened) }}>
                            <img src={medium} className="sub_image_card" style={{
                                width: '50px', height: '50px',
                                filter: mapDetailLevel === 'medium' ? 'brightness(100%)' : 'brightness(70%)'
                            }} />
                        </CardActionArea>
                    </Tooltip>
                </Card>
            </Slide>

            <Slide direction="up" in={opened} mountOnEnter unmountOnExit>
                <div style={{ position: 'absolute', marginLeft: '80px', bottom: 140, zIndex: 3, color: 'gray' }}>
                    <h3 className='labelOutline'>Medio</h3>
                </div>
            </Slide>

            <Slide direction="up" in={opened} mountOnEnter unmountOnExit>
                <Card elevation={2} className="sub_layer_card sub_position_3">
                    <Tooltip title="Sin curvas de nivel, parajes ni localidades en detalle" placement="right">
                        <CardActionArea onClick={() => { props.setLowDetail(); setMapDetailLevel('low'); setOpened(!opened) }}>
                            <img src={low} className="sub_image_card" style={{
                                width: '50px', height: '50px',
                                filter: mapDetailLevel === 'low' ? 'brightness(100%)' : 'brightness(70%)'
                            }} />
                        </CardActionArea>
                    </Tooltip>
                </Card>
            </Slide>

            <Slide direction="up" in={opened} mountOnEnter unmountOnExit>
                <div style={{ position: 'absolute', marginLeft: '80px', bottom: 200, zIndex: 3, color: 'gray' }}>
                    <h3 className='labelOutline'>Sencillo</h3>
                </div>
            </Slide>

        </div>
    );
}