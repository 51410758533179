import Agriculture from '@mui/icons-material/Agriculture';
import { Card, Avatar, Stack, Paper, Typography, CardContent } from '@mui/material'
import React from 'react'
import UserService from '../../services/user/userService';

import NumNotificactions from './NumNotificactions';
import NumParcelas from './NumParcelas';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import NumTareas from './NumTareas';
import EditUser from './EditUser';
import { IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

export default function MyProfile() {
  const [user, setUser] = React.useState({});
  const [openEditUser, setOpenEditUser] = React.useState(false);
  const { getUserInfo } = UserService();

  React.useEffect(() => {
    setUser(getUserInfo());
  }, []);

  function closeEditUser() {
    setOpenEditUser(false);
  }


  return (
    //React MUI Grid with one element in the middle and two below
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={3}>
        {/* Title */}
        <Grid item xs={12} md={12} lg={12}>
          <Paper
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ height: "100px", width: "100px", fontSize: "50px", mb: 3 }}>
              {getUserInfo().name.charAt(0).toUpperCase()}
            </Avatar>

            <Typography variant="h4" gutterBottom color='primary'>
              {getUserInfo().name}
            </Typography>

            <Typography variant="h6" gutterBottom>
              {getUserInfo().email}
            </Typography>

            {/* Boton para editar */}
            <IconButton
              aria-label="edit"
              onClick={() => setOpenEditUser(true)}
              color="primary"
            >
              <EditIcon />
            </IconButton>
          </Paper>
        </Grid>


        {/* Parcelas */}
        <Grid item xs={12} md={6} lg={4}>
          <Paper
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              height: 240,
              textAlign: 'center',
              justifyContent: 'center',
            }}
          >
            <NumParcelas />
          </Paper>
        </Grid>
        {/* Notificaciones */}
        <Grid item xs={12} md={6} lg={4}>
          <Paper
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              height: 240,
              textAlign: 'center',
              justifyContent: 'center',
            }}
          >
            <NumNotificactions />
          </Paper>
        </Grid>
        {/* Tareas */}
        <Grid item xs={12} md={6} lg={4}>
          <Paper
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              height: 240,
              textAlign: 'center',
              justifyContent: 'center',
            }}
          >
            <NumTareas />
          </Paper>
        </Grid>

      </Grid>
      <EditUser open={openEditUser} onClose={closeEditUser} user={user} setUser={setUser} />
    </Container>



  )
}
