import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, TextField } from '@mui/material';
import axios from 'axios';
import { useSnackbar } from 'notistack';

export default function AlertDialog({ onClose, open, user, setUser}) {
    const { enqueueSnackbar } = useSnackbar()

    const handleClose = () => {
        onClose();
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        axios.put(process.env.REACT_APP_API_BASE_URL + "/users/" + user.username, {
            name: data.get('name'),
            email: data.get('email'),
            role: user.role,

        }).then(res => {
            enqueueSnackbar('Usuario editado correctamente, los cambios se reflejarán al volver a iniciar sesión', { variant: 'success' })
            onClose();
        }
        ).catch(err => {
            enqueueSnackbar(err.response.data.message, { variant: 'error' })
            onClose();
        }
        );
    }


    return (
        <div>

            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth={true}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Editar usuario"}</DialogTitle>
                <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                    <DialogContent>

                        <TextField
                            disabled={true}
                            margin="normal"
                            fullWidth
                            id="username"
                            label="Usuario"
                            name="username"
                            autoComplete="username"
                            defaultValue={user.username}
                        />
                        <TextField
                            margin="normal"
                            fullWidth
                            id="name"
                            label="Nombre"
                            name="name"
                            autoComplete="name"
                            defaultValue={user.name}
                        />
                        <TextField
                            margin="normal"
                            fullWidth
                            id="email"
                            label="Email"
                            name="email"
                            autoComplete="email"
                            defaultValue={user.email}
                        />


                    </DialogContent>

                    <DialogActions>
                        <Button onClick={handleClose} color="primary" autoFocus>
                            Cancelar
                        </Button>
                        <Button type="submit" color="primary" autoFocus>
                            Editar
                        </Button>
                    </DialogActions>
                </Box>


            </Dialog>

        </div>
    );
}
