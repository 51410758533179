import { Container, Grid, Paper, Skeleton } from '@mui/material';
import axios from 'axios';
import React from 'react'
import { useEffect } from 'react';
import { useParams } from 'react-router-dom'
import Dashboard from './Dashboard';


export default function ParcelaDetails() {
    //get id from url
    const [parcela, setParcela] = React.useState(null);
    const [loading, setLoading] = React.useState(true);
    const { id } = useParams();



    const params = {
        _id: id
    }

    useEffect(() => {
        axios.get(process.env.REACT_APP_API_BASE_URL + '/parcelas/getById', { params })
            .then(res => {
                setParcela(res.data.parcela);
                setLoading(false);
            }
            ).catch(err => {
                console.log(err);
            });

        return () => {
            setParcela(null);
            setLoading(true);
        }
    }, []);

    if (loading) {
        return (
            <div>
               <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
                {/* Title */}
                <Grid item xs={12} md={12} lg={12}>
                    <Paper
                        sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Skeleton animation="wave" variant="rect" width="100%" height={400} />


                    </Paper>
                </Grid>


                {/* Chart */}
                <Grid item xs={12} md={8} lg={9}>
                    <Paper
                        sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            height: 240,
                        }}
                    >
                        <Skeleton animation="wave" variant="rect" width="100%" height={240} />
                    </Paper>
                </Grid>
                {/* Recent Deposits */}
                <Grid item xs={12} md={4} lg={3}>
                    <Paper
                        sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            height: 240,
                        }}
                    >
                        <Skeleton animation="wave" variant="rect" width="100%" height={240}/>
                    </Paper>
                </Grid>
            </Grid>
        </Container>

            </div>
        )

    } else {
        return (
            <div>
                <Dashboard parcela={parcela}/>
            </div>
        )
    }
}
